<template>
  <v-container>
    <v-row class="mt-12">
      <v-col cols="12">
        <h2 style="color: #2F65AF;">Download datasets</h2>
        <div
          class="divider"
          style="background-color: #2F65AF; height: 3px;"
        ></div>
      </v-col>
      <v-col cols="12">
        <DatasetsTable />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatasetsTable from "@/components/DatasetsTable.vue";

export default {
  name: "datasets",
  components: {
    DatasetsTable
  },
  methods: {}
};
</script>
