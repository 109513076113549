<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="-1"
      :sort-desc="[false, true]"
      multi-sort
      :loading="isLoading"
      loading-text="Loading data..."
      style="font-weight: bold"
    >
      <template v-slot:item.link="{ item }">
        <v-btn
          :href="item.link"
          color="#2F65AF"
          dark
          style="font-weight: bolder;"
          target="_blank"
          >Download
        </v-btn></template
      >
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      isLoading: true,
      headers: [
        {
          text: "No",
          align: "left",
          sortable: true,
          value: "no"
        },
        {
          text: "Detail",
          sortable: true,
          value: "detail"
        },
        {
          text: "Number of compound",
          filterable: false,
          value: "noOfCompound"
        },
        {
          text: "Download link",
          filterable: false,
          value: "link"
        }
      ]
    };
  },
  created() {
    this.isLoading = true;
    this.getDatasets();
  },
  methods: {
    getDatasets() {
      // console.log("Loading scores...");
      this.data = [
        {
          no: 1,
          detail: "Compounds against wild type EGFR (All dataset)",
          noOfCompound: "6,359",
          link:
            "https://drive.google.com/file/d/1mTmtmU0Ci573BZk4qZMjuYawD9gMq3rV/view?usp=sharing"
        },
        {
          no: 2,
          detail: "Compounds against wild type EGFR (Internal dataset)",
          noOfCompound: "5,088",
          link:
            "https://drive.google.com/file/d/1mTmtmU0Ci573BZk4qZMjuYawD9gMq3rV/view?usp=sharing"
        },
        {
          no: 3,
          detail: "Compounds against wild type EGFR (External dataset)",
          noOfCompound: "1,271",
          link:
            "https://drive.google.com/file/d/1mTmtmU0Ci573BZk4qZMjuYawD9gMq3rV/view?usp=sharing"
        }
      ];
      this.isLoading = false;
    }
  }
};
</script>
